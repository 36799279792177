<template>
  <div class="container-fluid mx-0 px-0 px-0">
      <div class="bg-img-sistem">
        <p class="title text-white div-a2" data-aos="fade-up" style="overflow-wrap:break-word;">
          {{textos_serv_propietario.seccion_1_titulo_serv_prop}}
        </p>
      </div>
     
      <div class="container-fluid text-justify px-0">
        <p class="ml-md-4 ">{{textos_serv_propietario.seccion_1_descripcion_serv_prop}}</p>       
          <div class="">
              <div class="container py-5 px-3 my-3">
                <p class="h2"><b> <b-icon icon="check-square" scale="1.3" class="mr-3 color-blue-sky mt-4 responsive_propietario_iconos"></b-icon>{{textos_serv_propietario.seccion_2_title_list_1_serv_prop}}</b></p>
                <div class="row mt-3 px-mx-0 px-3">
                  
                  <div class="col-12 ml-md-3 mb-2 padding_0">
                    <p class="my-2 "><b>{{textos_serv_propietario.seccion_2_listItem_1_serv_prop}}</b></p><br>
                  </div>
                </div>
                <div class="row">
                  <div class="col-1 mr-0 pr-0 py-2 text-right responsive_propietario_iconos">
                    <b-icon icon="check2" scale="2.5" class="mr-3 color-blue-sky "></b-icon>
                  </div>
                  <div class="col-11 pl-3 ml-0 ">
                    <p class=" pb-0">{{textos_serv_propietario.seccion_2_listItem_2_serv_prop}}</p><br>
                  </div>
                </div>
                <div class="row ">
                  <div class="col-1 mr-0 pr-0 py-2 text-right responsive_propietario_iconos">
                    <b-icon icon="check2" scale="2.5" class="mr-3 color-blue-sky "></b-icon>
                  </div>
                  <div class="col-11 pl-3 ml-0 ">
                    <p class="my-2">{{textos_serv_propietario.seccion_2_listItem_3_serv_prop}}</p><br>
                  </div>
                </div>
              </div>       

                     <!-- NO MOSTRAR   -->
              <div style="display: none;" class="bg-gray py-5 my-3 mx-0">
                <div class="container">
                  <p class="h2 "><b-icon icon="check-square" scale="1.3" class="mr-3 color-blue-sky mt-4 responsive_propietario_iconos"></b-icon><b>{{textos_serv_propietario.seccion_3_title_list_2_serv_prop}}</b></p>
                  <div class="px-ms-5 mt-5">
                    <div class="row ">
                      <div class="col-1 mr-0 pr-0 py-2 text-right responsive_propietario_iconos">
                        <b-icon icon="check2" scale="2.5" class="mr-3 color-blue-sky "></b-icon>
                      </div>
                      <div class="col-11 pl-3 ml-0 ">
                        <p class="mb pb-0">{{textos_serv_propietario.seccion_3_listItem_1_serv_prop}}</p><br>
                      </div>
                    </div>
                    <div class="row ">
                      <div class="col-1 mr-0 pr-0 py-2 text-right responsive_propietario_iconos">
                        <b-icon icon="check2" scale="2.5" class="mr-3 color-blue-sky "></b-icon>
                      </div>
                      <div class="col-11 pl-3 ml-0  ">
                        <p class="mb pb-0">{{textos_serv_propietario.seccion_3_listItem_3_serv_prop}}</p><br>
                      </div>
                    </div>
                    <div class="row  ">
                      <div class="col-1 mr-0 pr-0 py-2 text-right responsive_propietario_iconos">
                        <b-icon icon="check2" scale="2.5" class="mr-3 color-blue-sky "></b-icon>
                      </div>
                      <div class="col-11 pl-3 ml-0 ">
                        <p class="my-2">{{textos_serv_propietario.seccion_3_listItem_2_serv_prop}}</p><br>
                      </div>
                    </div>
                    <!-- <div class="row "> -->
                      
                      
                        <!-- <div class="col-5  offset-1 p-3 bg-light shadow border-top-blue">
                          <p class="my-2">{{textos_serv_propietario.seccion_3_listItem_1_serv_prop}}</p><br>
                        </div>
                        <div class="col-5 ml-5 pl-0  p-3 bg-light shadow border-top-blue">
                          <p class="my-2">{{textos_serv_propietario.seccion_3_listItem_3_serv_prop}}</p><br>
                        </div>
                      </div>
                      <div class="row mt-5">
                        <div class="col-6 offset-3 p-3 bg-light shadow border-top-blue">
                        <p class="my-2">{{textos_serv_propietario.seccion_3_listItem_2_serv_prop}}</p><br>
                      </div> -->
                    <!-- </div> -->
                    
                  </div>   
                </div>
              </div>
              <div style="display: none;" class="container my-3 py-5">
                <p class="h2"><b-icon icon="check-square" scale="1.3" class="mr-3 color-blue-sky mt-4 responsive_propietario_iconos"></b-icon><b>{{textos_serv_propietario.seccion_4_title_list_3_serv_prop}}</b></p>
                <div class="mt-5">
                  <!-- <b-table head-variant="dark" responsive  hover :items="items"></b-table> -->
                  
                  <b-table
                    :items="items_table"
                    :fields="fields" 
                    class="text-center"
                    responsive
                    sticky-header="true"
                    head-variant="dark" stacked="sm" hover
                  ></b-table>
                </div>
              </div>



              <!-- HASTA AQUI -->
              <div style="margin-bottom: 0 !important;" class="bg-gray py-5 my-3 ">
                <div class="container px-3">
                  <p class="h2"><b><b-icon icon="check-square" scale="1.3" class="mr-3 color-blue-sky mt-4 responsive_propietario_iconos"></b-icon>{{textos_serv_propietario.seccion_5_title_list_4_serv_prop}}</b></p>
                  <div class="row mt-5">
                    <div class="col-1 mr-0 pr-0 py-2 text-right responsive_propietario_iconos">
                      <b-icon icon="check2" scale="2.5" class="mr-3 color-blue-sky "></b-icon>
                    </div>
                    <div class="col-11 pl-3 ml-0">
                      <p class="my-2">{{textos_serv_propietario.seccion_5_listItem_1_serv_prop}}</p><br>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-1 mr-0 pr-0 py-2 text-right responsive_propietario_iconos">
                      <b-icon icon="check2" scale="2.5" class="mr-3 color-blue-sky "></b-icon>
                    </div>
                    <div class="col-11 pl-3 ml-0 ">
                      <p class="my-2">{{textos_serv_propietario.seccion_5_listItem_2_serv_prop}}</p><br>
                    </div>
                  </div>
                </div> 
              </div>
                                         
              <div style="display: none;" class="py-5 my-3 ">
                <div class="container  px-3">
                  <p class="h2"><b-icon icon="check-square" scale="1.3" class="mr-4 color-blue-sky mt-4 responsive_propietario_iconos"></b-icon><b>{{textos_serv_propietario.seccion_6_title_list_5_serv_prop}}</b></p>
                  <div class="row mt-5">
                    <div class="col-1 mr-0 pr-0 py-2 text-right responsive_propietario_iconos">
                      <b-icon icon="check2" scale="2.5" class="mr-3 color-blue-sky "></b-icon>
                    </div>
                    <div class="col-11 pl-3 ml-0">
                      <p class="my-2">{{textos_serv_propietario.seccion_6_listItem_1_serv_prop}}</p><br>
                    </div>
                  </div>
                </div>
              </div>
              <div style="margin-top:0 !important ; background-color: white;" class="bg-gray mt-3 py-5  mb-0 pb-0">
                <div class="container  px-3">
                  <p class="h2"><b><b-icon icon="check-square" scale="1.3" class="mr-3 color-blue-sky mt-4 responsive_propietario_iconos"></b-icon>{{textos_serv_propietario.seccion_7_title_list_6_serv_prop}}</b></p>
                  <div class="row mt-5">
                    <div class="col-1 mr-0 pr-0 py-2 text-right responsive_propietario_iconos">
                      <b-icon icon="check2" scale="2.5" class="mr-3 color-blue-sky "></b-icon>
                    </div>
                    <div class="col-11 pl-3 ml-0">
                      <p class="my-2">{{textos_serv_propietario.seccion_7_listItem_1_serv_prop}}</p><br>
                    </div>
                  </div>
                  
                </div> 
              </div>
              <!-- <p>{{textos_serv_propietario.seccion_7_title_list_6_serv_prop}}</p>
              <div class="row">
                <div class="col-1 mr-0 pr-0 py-2 text-right">
                <b-icon icon="circle-fill" scale="0.8" variant="info" class="mr-4"></b-icon>
              </div>
                <div class="col-11 pl-0 ml-0">
                  <p class="my-2">{{textos_serv_propietario.seccion_7_listItem_1_serv_prop}}</p><br>
                </div>
              </div> -->
          </div>          
      </div>
  </div>
</template>

<script>
export default {
    data() {
      return {
        // items: [
        //   { Servicio: 'Limpieza',APARTAMENTO_1_DORMITORIO: 'Dic',APARTAMENTO_2_DORMITORIOS: 'Dic',APARTAMENTO_3_DORMITORIOS: 'Dic', APARTAMENTO_4_DORMITORIOS: 'Dickerson', APARTAMENTO_5_DORMITORIOS: 'Macdonald' },
        //   { Servicio: 'Lavandería',APARTAMENTO_1_DORMITORIO: 'Lar',APARTAMENTO_2_DORMITORIOS: 'Lar',APARTAMENTO_3_DORMITORIOS: 'Lar', APARTAMENTO_4_DORMITORIOS: 'Larsen', APARTAMENTO_5_DORMITORIOS: 'Shaw' },
        //   { Servicio: 'Checkin o desplazamiento',APARTAMENTO_1_DORMITORIO: 'Gen',APARTAMENTO_2_DORMITORIOS: 'Gen',APARTAMENTO_3_DORMITORIOS: 'Gen', APARTAMENTO_4_DORMITORIOS: 'Geneva', APARTAMENTO_5_DORMITORIOS: 'Wilson' },
        //   { Servicio: 'Late Checkin',APARTAMENTO_1_DORMITORIO: 'Jam',APARTAMENTO_2_DORMITORIOS: 'Jam',APARTAMENTO_3_DORMITORIOS: 'Jam', APARTAMENTO_4_DORMITORIOS: 'Jami', APARTAMENTO_5_DORMITORIOS: 'Carney' },
        //   { Servicio: 'TOTAL RESERVA',APARTAMENTO_1_DORMITORIO: 'Dic',APARTAMENTO_2_DORMITORIOS: 'Dic',APARTAMENTO_3_DORMITORIOS: 'Dic', APARTAMENTO_4_DORMITORIOS: 'Dickerson', APARTAMENTO_5_DORMITORIOS: 'Macdonald' },
          
        // ]
        textos_serv_propietario:{
          // seccion_1_titulo_sistem_colab:'SERVICIOS DE APOYO AL PROPIEATRIO ',
          // seccion_1_descripcion_sistem_colab:'Nos encargamos del Servicio de limpieza y lavandería, también nos encargamos del Servicio de entrega y recepción de llaves y de muchos servicios mas.',
          // seccion_2_title_list_1_serv_prop:'-1 Servicio de entrega y recepción de llaves.',
          // seccion_2_listItem_1_serv_prop:'LINETOR se encargaría única y exclusivamente de la recepción y control del cliente de manera remota durante la estancia en la propiedad. ',
          // seccion_2_listItem_2_serv_prop:'Si se hace remotamente con sistemas electrónicos se cobraría un precio por reserva de 6€ aproximadamente.',
          // seccion_2_listItem_3_serv_prop:'Si el check-in check-out se hace de forma presencial se cobraría por tiempo empleado a razón de 11 € la hora más 4€ por desplazamiento.',
          // seccion_3_title_list_2_serv_prop:'-2 Servicio de limpieza y lavandería por horas.',
          // seccion_3_listItem_1_serv_prop:'17% sobre 1050 ( 178.50 €) descuenta la plataforma (booking o airbnb). El propietario recibiría en su cuenta bancaria 1050-178.50=871.5€',
          // seccion_3_listItem_2_serv_prop:'Los precios de lavandería se cobran aproximadamente a 3.5 € por plaza.',
          // seccion_3_listItem_3_serv_prop:'Servicio de acuda. Este servicio también se factura por horas al mismo precio 11€ la hora más 4€ de desplazamiento en horario diurno y 12€ la hora más 6€ de desplazamiento en el caso de servicio nocturno entre las 22 y 8 de la mañana.',
          // seccion_4_title_list_3_serv_prop:'-3 Servicio de limpieza y lavandería por reserva.',
          // seccion_5_title_list_4_serv_prop:'-4 Electrónica automatización acceso puerta comunidad',
          // seccion_5_listItem_1_serv_prop:'Con este sistema el huésped puede abrir la puerta de la comunidad sin disponer de la llave. Se conecta al telefonillo de la casa y a través de internet se podrá accionar la apertura de la puerta. El coste de esta instalación es de 200 €.',
          // seccion_5_listItem_2_serv_prop:'(SIN PEDIR PERMISO A LA COMUNIDAD)',   
          // seccion_6_title_list_5_serv_prop:'-5 Electrónica automatización acceso puerta vivienda.',
          // seccion_6_listItem_1_serv_prop:'Por aproximadamente 300 euros se puede automatizar la apertura de la puerta de la vivienda. Para dar precio exacto se necesita conocer los detalles de la puerta actual.', 
          // seccion_7_title_list_6_serv_prop:'-6 Sistema informático integral para la gestión de la propiedad.',
          // seccion_7_listItem_1_serv_prop:'Con este sistema y con conexión de internet se puede gestionar tanto los accesos como los consumos de energía etc ...A Través de un autómata programable se pueden controlar todo tipo de actuadores y sensores para conseguir optimizar la gestión integral de la propiedad. Pida presupuesto.',             
        },
        items_table: [
          // { Servicio: 'Limpieza', APARTAMENTO_1_DORMITORIO: '25,2 €',APARTAMENTO_2_DORMITORIO: '32,4 €',APARTAMENTO_3_DORMITORIO: '39,6 €', APARTAMENTO_4_DORMITORIO: '46,8 €', APARTAMENTO_5_DORMITORIO: '54 €' },
          // { Servicio: 'Lavandería', APARTAMENTO_1_DORMITORIO: '7,2 €',APARTAMENTO_2_DORMITORIO: '14,4 €',APARTAMENTO_3_DORMITORIO: '21,6 €', APARTAMENTO_4_DORMITORIO: '28,8 €', APARTAMENTO_5_DORMITORIO: '36 €' },
          // { Servicio: 'Checkin o desplazamiento', APARTAMENTO_1_DORMITORIO: '14,4 €',APARTAMENTO_2_DORMITORIO: '14,4 €',APARTAMENTO_3_DORMITORIO: '14,4 €', APARTAMENTO_4_DORMITORIO: '14,4 €', APARTAMENTO_5_DORMITORIO: '14,4 €' },
          // { Servicio: 'Late Checkin', APARTAMENTO_1_DORMITORIO: '14,4 €',APARTAMENTO_2_DORMITORIO: '14,4 €',APARTAMENTO_3_DORMITORIO: '14,4 €', APARTAMENTO_4_DORMITORIO: '14,4 €', APARTAMENTO_5_DORMITORIO: '14,4 €' },
          // { Servicio: 'TOTAL RESERVA', APARTAMENTO_1_DORMITORIO: '46,8 €',APARTAMENTO_2_DORMITORIO: '61,2 €',APARTAMENTO_3_DORMITORIO: '75,6 €', APARTAMENTO_4_DORMITORIO: '90 €', APARTAMENTO_5_DORMITORIO: '104,4 €' },
        ],
        fields: [

        ],
        textos:{}
      }
    },
    async mounted(){
        this.$store.watch(
          (state) => [state.recarga].join(),
          async () => {
            // this.showItemsNumber();
            // this.refreshButton();
            this.textos = []
            this.textos = JSON.parse(localStorage.getItem('text'))
            await this.llenarCampos()
            //console.log(this.textos)
          }
        );
        this.textos = JSON.parse(localStorage.getItem('text'))
        await this.llenarCampos()
      },
      methods:{
        async llenarCampos(){
          this.fields = []
          this.fields.splice(0, this.fields.length);


          this.textos_serv_propietario={
            seccion_1_titulo_serv_prop: this.textos.seccion_1_titulo_serv_prop,
            seccion_1_descripcion_serv_prop: this.textos.seccion_1_descripcion_serv_prop,
            seccion_2_title_list_1_serv_prop: this.textos.seccion_2_title_list_1_serv_prop,
            seccion_2_listItem_1_serv_prop: this.textos.seccion_2_listItem_1_serv_prop,
            seccion_2_listItem_2_serv_prop: this.textos.seccion_2_listItem_2_serv_prop,
            seccion_2_listItem_3_serv_prop: this.textos.seccion_2_listItem_3_serv_prop,
            seccion_3_title_list_2_serv_prop: this.textos.seccion_3_title_list_2_serv_prop,
            seccion_3_listItem_1_serv_prop: this.textos.seccion_3_listItem_1_serv_prop,
            seccion_3_listItem_2_serv_prop: this.textos.seccion_3_listItem_2_serv_prop,
            seccion_3_listItem_3_serv_prop: this.textos.seccion_3_listItem_3_serv_prop,
            seccion_4_title_list_3_serv_prop: this.textos.seccion_4_title_list_3_serv_prop,
            seccion_5_title_list_4_serv_prop: this.textos.seccion_5_title_list_4_serv_prop,
            seccion_5_listItem_1_serv_prop: this.textos.seccion_5_listItem_1_serv_prop,
            seccion_5_listItem_2_serv_prop: this.textos.seccion_5_listItem_2_serv_prop,   
            seccion_6_title_list_5_serv_prop: this.textos.seccion_6_title_list_5_serv_prop,
            seccion_6_listItem_1_serv_prop: this.textos.seccion_6_listItem_1_serv_prop, 
            seccion_7_title_list_6_serv_prop: this.textos.seccion_7_title_list_6_serv_prop,
            seccion_7_listItem_1_serv_prop: this.textos.seccion_7_listItem_1_serv_prop,          
          },
          // this.items_table={
          //   this.items[0].APARTAMENTO_1_DORMITORIO: this.textos.tabla_c_t1s1
          

        this.items_table= [
          { Servicio: this.textos.tabla_servicio1, APARTAMENTO_1_DORMITORIO: this.textos.tabla_c_t1s1, APARTAMENTO_2_DORMITORIO: this.textos.tabla_c_t2s1,APARTAMENTO_3_DORMITORIO: this.textos.tabla_c_t3s1, APARTAMENTO_4_DORMITORIO: this.textos.tabla_c_t4s1, APARTAMENTO_5_DORMITORIO: this.textos.tabla_c_t5s1, },
          { Servicio: this.textos.tabla_servicio2, APARTAMENTO_1_DORMITORIO: this.textos.tabla_c_t1s2, APARTAMENTO_2_DORMITORIO: this.textos.tabla_c_t2s2,APARTAMENTO_3_DORMITORIO: this.textos.tabla_c_t3s2, APARTAMENTO_4_DORMITORIO: this.textos.tabla_c_t4s2, APARTAMENTO_5_DORMITORIO: this.textos.tabla_c_t5s2, },
          { Servicio: this.textos.tabla_servicio3, APARTAMENTO_1_DORMITORIO: this.textos.tabla_c_t1s3, APARTAMENTO_2_DORMITORIO: this.textos.tabla_c_t2s3,APARTAMENTO_3_DORMITORIO: this.textos.tabla_c_t3s3, APARTAMENTO_4_DORMITORIO: this.textos.tabla_c_t4s3, APARTAMENTO_5_DORMITORIO: this.textos.tabla_c_t5s3, },
          { Servicio: this.textos.tabla_servicio4, APARTAMENTO_1_DORMITORIO: this.textos.tabla_c_t1s4, APARTAMENTO_2_DORMITORIO: this.textos.tabla_c_t2s4,APARTAMENTO_3_DORMITORIO: this.textos.tabla_c_t3s4, APARTAMENTO_4_DORMITORIO: this.textos.tabla_c_t4s4, APARTAMENTO_5_DORMITORIO: this.textos.tabla_c_t5s4, },
          { Servicio: this.textos.tabla_servicio5, APARTAMENTO_1_DORMITORIO: this.textos.tabla_c_t1s5, APARTAMENTO_2_DORMITORIO: this.textos.tabla_c_t2s5,APARTAMENTO_3_DORMITORIO: this.textos.tabla_c_t3s5, APARTAMENTO_4_DORMITORIO: this.textos.tabla_c_t4s5, APARTAMENTO_5_DORMITORIO: this.textos.tabla_c_t5s5, },
        ],
        this.fields= [
          { key: 'Servicio', label: '' },
          { key: 'APARTAMENTO_1_DORMITORIO', label: this.textos.tabla_titulo1 != null ? this.textos.tabla_titulo1 : '' },
          { key: 'APARTAMENTO_2_DORMITORIO', label: this.textos.tabla_titulo2 != null ? this.textos.tabla_titulo2 : '' },
          { key: 'APARTAMENTO_3_DORMITORIO', label: this.textos.tabla_titulo3 != null ? this.textos.tabla_titulo3 : '' },
          { key: 'APARTAMENTO_4_DORMITORIO', label: this.textos.tabla_titulo4 != null ? this.textos.tabla_titulo4 : '' },
          { key: 'APARTAMENTO_5_DORMITORIO', label: this.textos.tabla_titulo5 != null ? this.textos.tabla_titulo5 : '' }
        ]



         // console.log('cards seccion 3', this.cards_seccion_5)


        }
      }
  }
</script>

<style>
.table .thead-dark th {
    color: #fff !important;
    background-image: url("/img/5.jpg");

  /* Set a specific height */
  

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.div-a2 {
  padding-top: 180px;
  width: 66% !important;
  text-align: center !important;
  margin-left: 17%;
}
.bg-img-sistem {
  /* The image used */
  background-image: url("/img/5.jpg");

  /* Set a specific height */
  height: 460px;

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
@media (max-width: 480px){
  .responsive_propietario_iconos{
    display: none !important;
  }
  .padding_0{
    padding: 0 !important;
  }
}
</style>